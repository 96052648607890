.box {
    max-width: 21rem;
    min-width: 17rem;
    max-height: 7.25rem;
    background: #F6FFFB;
    border: 1px solid var(--border-color);
    display: flex;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 3rem;
    padding-right: 4.5rem;
    margin-bottom: 4rem ;
    border-radius: 5px;
    transition: .25s;

    .box-numb p {
        font-size: 4rem;
        color: var(--green);
        font-weight: bold;
        transition: .25s;
    }

    .box-text {
        color: #303030;
        font-size: 1.16rem;
        margin-left: 1.6rem;
        transition: .25s;
    }
}

.loading-box {
    background: linear-gradient(-45deg, #efefef, #ffffff, #efefef, #ffffff);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    border-radius: 5px;
    height: 135px;
    width: 25%;
    
    .box-text, .box-numb p {
        color: transparent;
    }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.box:hover{
    border: 1px solid var(--green);
    cursor: pointer;
}

@media screen and (max-width:1000px){
    .box{
        flex-direction: column;
        max-height: none;
        padding: 0 .5rem;
        min-width: 7rem;
        max-width: 50px!important;
    }
}