@import "./stylesheets/general/colors";
@import "./stylesheets/general/fonts";
@import "./stylesheets/general/general";
@import "./stylesheets/general/variables";

:root {
  --global-green: #f6fffb;
  --gray-border: #e9e9e9;
  --border-color: #e9e9e9;
  --green: #23b574;
  --red: #eb1b23;
  --text-color: #303030;
  --global-red: #fce1e3;
}
.custom-loading-animation-swal-popup {
  background-color: transparent !important;
}

.error {
  border: 1px solid var(--red) !important;

  input,
  svg {
    color: var(--red) !important;
  }
}

p,
td {
  font-size: 1.16rem;
  font-family: Arial, Helvetica, sans-serif;
}

a {
  text-decoration: none;
  color: inherit;
}

select {
  border: 1px solid var(--gray-border);
  border-radius: 5px;
  padding: 0.66rem 1.16rem;

  margin: 0rem 0.7rem;

  option {
    color: #303030;
    font-size: 0.9rem;
  }
}

.email {
  color: var(--green);
  text-decoration: underline;
  opacity: 1;
  margin-left: 0.5rem;
  font-size: 1.16rem;
}

#error {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  text-align: center;
}

#main-container {
  display: flex;
}

.wrapper {
  width: 85%;
}

.page-content {
  padding: 1.5rem 0rem 6.2rem 2.4rem;
}

@media screen and (max-width: 1200px) {
  html {
    font-size: 10px;
    overflow-x: visible;
  }

  .grey-line {
    width: 59vh;
  }

  #grey-line {
    width: 100% !important;
  }

  #main-container {
    flex-direction: column-reverse;
    justify-content: flex-end;
    min-height: 100vh;

    .page-title {
      .cont {
        padding-top: 20px;
        margin: 0;
        margin-bottom: 20px;
        display: grid;
        grid-template-columns: 6rem auto;

        #responsive-logo {
          grid-row-end: 3;
          grid-row-start: 1;
        }

        h3 {
          margin: 0;
          margin-left: 0;
        }

        .breadcrumbs {
          grid-column-start: 2;
        }
      }
    }

    .home .page-title .cont h3 {
      grid-row-end: 3;
      grid-row-start: 1;
    }
  }

  .data-table {
    .table-wrapper {
      overflow-x: auto;
    }

    .filter-wrapper {
      flex-wrap: wrap;
      gap: 6px 0px;

      .searchbar {
        width: 100%;
      }

      .status-filter {
        margin-right: 6px;
      }

      .status-filter,
      .date-filter {
        width: calc(50% - 3px);

        .custom-select {
          margin-left: 0;
        }

        select {
          width: 100%;
        }
      }
    }
  }

  .page-content {
    padding: 0px 0px 0px 12px;
  }

  .wrapper {
    width: 95%;
    margin: 0px auto;
  }
}

.table-wrapper {
  overflow-x: auto;
}

.filter-wrapper {
  margin: 1rem 0;
  display: flex;
}

.searchbar {
  position: relative;
  flex-grow: 1;
}

.searchbar input {
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  padding: 0.75rem 1rem;
  width: 100%;
}

.searchbar svg {
  position: absolute;
  right: 1rem;
  top: 0.75rem;
  width: 1.4rem;
  height: 1.4rem;
}

#coming-soon {
  h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: var(--green);
  }
}

.flex {
  display: flex;
}

.flex.column {
  flex-direction: column;
}

.flex.jc-c {
  justify-content: center;
}

.flex.jc-fe {
  justify-content: flex-end;
}

.flex.jc-fs {
  justify-content: flex-start;
}

.flex.jc-sb {
  justify-content: space-between;
}

.flex.jc-se {
  justify-content: space-evenly;
}

.flex.jc-sa {
  justify-content: space-around;
}

.flex.ai-c {
  align-items: center;
}

.flex.ai-fe {
  align-items: flex-end;
}

.flex.ai-fs {
  align-items: flex-start;
}

.ta-center {
  text-align: center;
}

.ta-left {
  text-align: left;
}

.ta-right {
  text-align: right;
}
