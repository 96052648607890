.search-bar {
    border: 1px solid var(--gray-border);
    color: #303030;
    font-size: 1rem;
    padding: 0.75rem 0 0.75rem 1.16rem;
    border-radius: 5px;
    min-width: 50vw;
    margin-bottom: 2rem;
}

.data-table a p {
    transition: .25s;
}

.data-table a p:hover {
    color: var(--green);
}

.filter-wrapper {
    .custom-select {
        margin-left: .5rem
    }
}

@media screen and (max-width: 1200px) {
    #shipments {
        padding-top: 0 !important;
    }
}