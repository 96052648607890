#delete-user {
  width: 100%;
  max-width: 90px;

  .delete-user-button {
    font-size: 12px !important;
    width: 100%;
    padding: 7px 0;
    border-radius: 5px;
  }
}

.delete-client-overlay {
  background: rgba(246, 255, 251, 0.5);

  .react-confirm-alert-body {
    text-align: center;

    h1 {
      color: var(--text-color);
      margin-bottom: 12px;
    }

    .react-confirm-alert-button-group {
      justify-content: center;

      button[label="No"] {
        background-color: var(--green);
      }

      button[label="Yes"] {
        background-color: var(--red);
      }
    }
  }
}