#sort-date {
  padding: 9px 14px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  margin-bottom: 20px;
  margin-left: 0;
}

#reports {
  .charts {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1.75rem;
    .chart {
      min-width: 27.3rem;
      min-height: 23.2rem;
      border-radius: 5px;
      padding: 25px 22px;
      border: 1px solid var(--border-color);

      .chart-header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 3rem;

        .right-side {
          .total {
            color: #303030;
            font-size: 1.16rem;
            margin-bottom: 1rem;
          }

          .duties-data {
            font-size: 3rem;
            font-weight: bold;
          }
        }
      }

      .button {
        font-size: 1rem;
        color: white;
        background: var(--green);
        padding: 7px 13px;
        border-radius: 5px;
      }
    }

    #custom-report {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      p {
        font-size: 1.16rem;
        margin-bottom: 1.5rem;
      }

      select {
        margin: 0.75rem 0;
        width: 16.1rem;
      }

      a {
        margin-top: 1.25rem;
      }
    }
  }
  .reports-bottom {
    h3 {
      margin-bottom: 2.42rem;
      margin-top: 2.75rem;
      font-size: 1.33rem;
    }
  }
}
