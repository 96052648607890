$--container-width: 75%;

.multiform {
    h3 {
        border: none;
    }

    .trucker-form {
        margin-top: 1rem;

        .error-msg {
            font-weight: normal;
            margin-top: 5px;
        }

        .new-email,
        .save-trucker {
            background-color: var(--green);
            border: none;
            color: white;
            border-radius: 5px;
            padding: 7px 15px;
            height: fit-content;
            // Debugging styles to ensure they are applied
            border: 2px solid red;
        }
    }

    .delete-trucker {
        background-color: var(--red);
        display: block;
        width: fit-content;
        margin-left: auto;
    }

    .tab-header,
    .all {
        width: $--container-width;
    }

    .tab-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
    }

    .individual {
        padding: 17px 0;
        width: 100%;

        .name {
            font-size: 12px;
            color: var(--text-color);
            font-weight: bolder;
            margin-bottom: 16px;
        }

        .trucker-form {
            .name_phone {
                display: flex;
                gap: 0px 40px;
            }

            .input-section {
                .input-container {
                    width: 100%;

                    label {
                        margin-bottom: 5px;
                        display: block;
                        font-size: 14px;
                        line-height: 20px;
                        font-weight: lighter;

                        .error-msg {
                            color: var(--red);
                            font-weight: bolder;
                            text-transform: uppercase;
                        }
                    }

                    .input {
                        display: flex;
                        border: 1px solid var(--border-color);
                        border-radius: 5px;
                        padding: 13px 17px;
                        gap: 0px 10px;

                        .input-icon {
                            svg {
                                width: 18px;
                                height: 18px;
                                color: black;
                                opacity: 0.24;
                            }
                        }

                        input {
                            border: none;
                            font-size: 14px;
                            line-height: 20px;
                            width: 100%;
                        }

                        input:focus {
                            outline: none;
                        }
                    }
                }

                .bottom {
                    display: flex;
                    gap: 0px 33px;

                    .input {
                        width: 100%;
                        max-width: 639px;
                    }

                    .buttons {
                        display: flex;
                        gap: 0px 18px;

                        button {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 47px;
                            width: 47px;
                            border-radius: 5px;
                            border: 1px solid #707070;
                            background-color: white;
                        }
                    }
                }
            }

            .render-emails {
                display: flex;
                flex-direction: column;
                gap: 5px 0px;

                label {
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: lighter;
                }
            }

            .add-email {
                margin: 13px 0px;
            }
        }
    }
}

.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.modal {
    display: flex;
    flex-direction: column;
    gap: 12px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    z-index: 1000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    .buttons {
        display: flex;
        justify-content: flex-end;
        gap: 12px;
    }
}

button.new-trucker {
    background-color: var(--green);
    border: none;
    color: white;
    border-radius: 5px;
    padding: 7px 15px;
    height: fit-content;
    margin-left: auto; // Align button to the right
}

button.delete-trucker {
    background-color: var(--red);
    border: none;
    color: white;
    border-radius: 5px;
    padding: 7px 15px;
    height: fit-content;
}

@media screen and (max-width: 1200px) {
    .multiform {

        .all,
        .tab-header {
            width: 100%;
        }
    }

    .input-sections {
        .input-field {
            flex-direction: column;
            gap: 12px 0px;
            width: 100%;
        }
    }
}
