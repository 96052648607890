$--container-width: 75%;

#settings {
  padding-top: 57.5px;

  .individual-header {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    height: fit-content;
    margin-bottom: 0;
  }

  .error-message {
    padding: 12px 18px;
    border-radius: 5px;
    background-color: var(--global-red);
  }

  form {
    h3.name {
      margin-bottom: 0 !important;
    }

    button.new-trucker,
    button.delete-trucker,
    button.new-warehouse,
    button.delete-warehouse,
    button.new-email,
    button[type="submit"] {
      border: none;
      color: white;
      border-radius: 5px;
      padding: 7px 15px;

      height: fit-content;
    }

    button[type="submit"] {
      margin-top: 24px;
      padding: 6px 32px;
    }

    .new-trucker,
    .new-warehouse,
    .new-email,
    button[type="submit"] {
      background-color: var(--green);
    }

    h3.section-title {
      margin-top: 2rem;
      font-size: 1.3rem;
      border-bottom: 1px solid var(--border-color);
      padding-bottom: 10px;
    }

    .divisor {
      width: 100%;
      height: 1px;
      background-color: var(--border-color);
    }

    .personal-information h3.section-title {
      margin-top: 0;
    }

    .input-sections {
      .input-field {
        width: $--container-width;
        display: flex;
        justify-content: space-between;

        // border-bottom: 1px solid var(--border-color);

        padding-top: 30px;
        padding-bottom: 19px;

        .left {
          p {
            font-size: 14px;
            color: var(--text-color);
          }

          .field-small-text {
            opacity: 0.5;
            margin-top: 9px;

            .mail {
              margin-left: 3px;
              color: var(--green) !important;
              text-decoration: underline;
            }
          }
        }

        .right {
          display: flex;
          align-items: center;

          .has-icon {
            position: relative;

            .input-icon {
              color: #303c42;
              opacity: 0.24;
              position: absolute;
              right: 1.5rem;
              bottom: 1rem;

              svg {
                font-size: 18px;
              }
            }
          }

          .blocked-input,
          .allowed-input {
            border: 1px solid var(--border-color);
            padding: 14px 25px;
            width: 316px;
            border-radius: 5px;
            color: var(--text-color);

            @media screen and (max-width: 1200px) {
              width: 100% !important;
            }

            p {
              opacity: 0.5;
            }

            input {
              border: none;
              width: 100%;
            }

            input:focus {
              outline: none;
            }
          }

          .blocked-input input {
            opacity: 0.5;
          }

          .documents-input {
            @media screen and (max-width: 1200px) {
              width: 100% !important;
            }
            .input {
              margin-bottom: 9px;
              display: flex;
              gap: 0px 10.67px;

              width: 316px;

              border: 1px solid var(--border-color);
              border-radius: 5px;
              padding: 14px 12px;

              @media screen and (max-width: 1200px) {
                width: 100%;
              }

              .input-icon {
                svg {
                  color: black;
                  opacity: 0.24;
                  // background-color: var(--border-color);
                  font-size: 26px;
                }
              }

              textarea:focus {
                outline: none;
              }

              textarea {
                border: none;
                color: black;
                font-size: 14px;
                width: 100%;
                min-height: 120px;
              }
            }

            .count {
              width: fit-content;
              margin-left: auto;

              p {
                font-size: 14px;
                color: var(--text-color);
                opacity: 0.5;
              }
            }
          }
        }
      }
    }

    .multiform {
      h3 {
        border: none;
      }

      .trucker-form {
        margin-top: 1rem;

        .error-msg {
          font-weight: normal;
          margin-top: 5px;
        }
      }

      .delete-trucker,
      .delete-warehouse {
        background-color: var(--red);
        display: block;
        width: fit-content;
        margin-left: auto;
      }

      .tab-header,
      .all {
        width: $--container-width;
      }

      .tab-header {
        // width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
      }

      .individual {
        padding: 17px 0;
        width: 100%;
      }

      .individual {
        .name {
          font-size: 12px;
          color: var(--text-color);
          font-weight: bolder;
          margin-bottom: 16px;
        }

        .trucker-form {
          .name_phone {
            display: flex;
            gap: 0px 40px;
          }

          .input-section {
            .input-container {
              width: 100%;

              label {
                margin-bottom: 5px;
                display: block;

                font-size: 14px;
                line-height: 20px;
                font-weight: lighter;

                .error-msg {
                  color: var(--red);
                  font-weight: bolder;
                  text-transform: uppercase;
                }
              }

              .input {
                display: flex;
                border: 1px solid var(--border-color);
                border-radius: 5px;
                padding: 13px 17px;
                gap: 0px 10px;

                .input-icon {
                  svg {
                    width: 18px;
                    height: 18px;
                    color: black;
                    opacity: 0.24;
                  }
                }

                input {
                  border: none;
                  font-size: 14px;
                  line-height: 20px;
                  width: 100%;
                }

                input:focus {
                  outline: none;
                }
              }
            }

            .bottom {
              display: flex;
              gap: 0px 33px;

              .input {
                width: 100%;
                max-width: 639px;
              }

              .buttons {
                display: flex;
                gap: 0px 18px;

                button {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 47px;
                  width: 47px;
                  border-radius: 5px;
                  border: 1px solid #707070;
                  background-color: white;
                }
              }
            }
          }

          .render-emails {
            display: flex;
            flex-direction: column;
            gap: 5px 0px;

            label {
              font-size: 14px;
              line-height: 20px;
              font-weight: lighter;
            }
          }

          .add-email {
            margin: 13px 0px;
          }
        }
      }
    }

    .multiform.warehouses {
      padding-top: 63px;
    }

    .warehouse-form {
      .input-container .select > div {
        min-height: 46px;
      }
    }
  }

  .alert {
    background: #eb1b2323;
    border-radius: 5px;
    font-size: 1.1rem;
    padding: 1rem;
    margin-top: 2rem;
  }

  .error-msg {
    color: var(--red);
    margin-top: 1rem;
  }
}

.current-email {
  color: var(--text-color);
  opacity: 0.5;
  font-size: 11px;
}

@media screen and (max-width: 1200px) {
  .page-content {
    padding: 0;
    padding-top: 20px !important;
  }

  #settings {
    label {
      font-size: 14px;
    }

    form {
      .multiform {
        .all,
        .tab-header {
          width: 100%;
        }
      }

      .input-sections {
        .input-field {
          flex-direction: column;
          gap: 12px 0px;
          width: 100%;
        }
      }
    }
  }

  .change-contacts {
    .inputs {
      gap: 0px 12px !important;
    }
  }

  .adress {
    flex-direction: column;

    .right {
      gap: 0px 12px;
    }
  }
}

// CC Emails Inputs
.main-email-bottom-margin {
  margin-bottom: 30px;
}
