#users {
  .header {
    .filters {
      align-items: center;

      #new-user-button {
        width: 90px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        border: none;
        border-radius: 5px;
        font-size: 12;
        background-color: var(--green);
      }
    }
  }
}

.table {
  .username {
    font-weight: 600;
  }

  .table-header {
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);

    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: var(--text-color);

    padding: 17.5px 0px;

    display: flex;
    justify-content: space-between;
    padding-right: 48.5px;
    padding-left: 11px;

    .left {
      display: flex;
      gap: 0px 179px;
    }

    .username,
    .role {
      width: 173px;
      display: flex;
      align-items: center;
      gap: 0px 12px;

      svg {
        position: relative;
        top: 3px;
      }
    }

    @media screen and (max-width: 1000px) {
      padding-right: 11px;

      .left {
        grid-gap: 0;

        .role {
          width: 7rem;
        }
      }
    }
  }

  .table-body {
    border-bottom: 1px solid var(--border-color);
    display: flex;
    justify-content: space-between;
    padding: 15px 48.5px 15px 11px;

    .username,
    .role {
      width: 173px;
    }

    .left {
      display: flex;
      gap: 0px 179px;

      .table-role {
        display: flex;
        align-items: center;
        gap: 0px 8px;
      }
    }

    .right {
      .actions {
        gap: 0px 12px;
      }
    }

    @media screen and (max-width: 1000px) {
      padding-right: 11px;

      .left {
        grid-gap: 0;

        .role {
          width: 7rem;
        }
      }

      .right .actions {
        grid-gap: 0 1rem;
      }
    }
  }
}