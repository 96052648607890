$side-mh: 55rem;

.navbar {
  position: sticky;
  top: 0;
  left: 0;
  max-width: 8.4rem;
  min-height: 100vh;
  max-height: $side-mh;

  background: var(--global-green);

  border-right: 1px solid var(--gray-border);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  padding: 3.3rem 3.2rem 7.5rem 3.2rem;

  img {
    width: 4rem;
  }

  svg {
    font-size: 28px;
    color: var(--green);
  }

  ul {
    height: 40rem;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;

    margin-top: 100px;

    li {
      .active {
        position: relative;
      }

      .active::after {
        content: ('');
        width: 4px;
        height: 37px;
        border-radius: 5px 0px 0px 5px;
        background: var(--green);
        position: absolute;
        top: -143%;
        right: -3.2rem;
      }

      text-align: center;

      img {
        width: 2rem;
      }

      .logout-btn {
        opacity: .5;
        cursor: pointer;

        svg {
          font-size: 28px;
          color: var(--green)
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  #nav-bar_main-img {
    display: none;
  }

  .navbar {
    margin-top: auto;
    position: sticky;
    bottom: 0;
    max-width: none;
    max-height: 75px;
    min-height: min-content;
    width: 100vw;
    z-index: 10;

    padding: 0 2rem;
  }

  ul {
    flex-direction: row !important;
    width: 100%;
    justify-content: space-around;

    margin-top: 0 !important;

    li {
      .active::after {
        right: -.5rem !important;
        top: -2.8rem !important;
        height: 4px !important;
        width: 37px !important;
        border-radius: 0px 0px 5px 5px !important;
      }
    }
  }
}