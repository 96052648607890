#grey-line {
  border-top: 1px solid #e9e9e9;
  width: calc(100vw - 8.4rem);
}
  .navigation-arrows {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    //position: absolute; /* Make it absolute */
    //right: 0; /* Align it to the right */
    //top: 0; /* Adjust this if you want to control the vertical positioning */
    align-items: center;
  
    .arrow-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      padding: 5px 10px;
      width: 150px; /* Set a fixed width for equal-sized buttons */
      background-color: var(--green);
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      text-align: center;
  
      .arrow-icon {
        width: 14px;
        height: 14px;
        fill: white;
        stroke: white;
        stroke-width: 1.5;
      }
  
      .arrow-text {
        font-size: 1.045rem;
        color: white;
        white-space: nowrap;
      }
  
      &:disabled {
        background-color: grey;
        color: black;
        cursor: not-allowed;
      }
    }
  }
  
#shipment-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .left-side {
    width: 55%;
    align-items: center;

    .form-content {
      .isf-inputs_container {
        display: flex;
        gap: 24px;
      }

      .isf-input {
        display: flex;
        flex-direction: column;
        margin: 16px 0;

        span {
          margin-bottom: 5px;
        }

        input {
          padding: 7px;
          border: 1px solid var(--border-color);
          border-radius: 5px;
          font-size: 12px;
        }
      }

      .isf-no {
        max-width: 40%;
      }

      .isf-date {
        width: 50%;

        input {
          width: 100%;
        }
      }

      .isf-buttons_container {
        display: flex;
        width: fit-content;
        margin-top: 1rem;
        align-items: center;

        .checkbox {
          width: 20px;
          height: 20px;
          border: 1px solid black;
          border-radius: 5px;
          cursor: pointer;
          margin-right: 0.5rem;
        }

        p {
          outline: none;
          border: none;
          border-radius: 5px;
        }

        input[type="submit"] {
          background-color: var(--green);
        }
      }
    }

    .form-content.released-content {
      p {
        margin-bottom: 0.5rem;
      }
    }

    .docs-wrapper {
      display: flex;
      flex-wrap: wrap;
      grid-gap: 1rem;
      padding: 1rem 0;
      margin-top: 2rem;
      margin-bottom: 1rem;

      .doc {
        width: 8rem;
        height: 10rem;
        text-align: center;
        border: 1px solid var(--border-color);
        border-radius: 5px;
        padding: 1.6rem 0;
        position: relative;

        p {
          color: #303030;
          opacity: 0.75;
          font-size: 0.9rem;
          width: 90%;
          margin: auto;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        svg {
          font-size: 3.3rem;
          color: #707070;
          opacity: 0.75;
          margin-bottom: 1.3rem;
        }

        .delete {
          position: absolute;
          background: #9a9a9a;
          border-radius: 100px;
          padding: 0.5rem;
          top: -1rem;
          right: -1rem;
          width: 2rem;
          height: 2rem;

          svg {
            margin-bottom: 0;
            opacity: 1;
            width: 0.85rem;
          }
        }
      }
    }

    .form-process {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      width: 100%;

      .form-step {
        width: 100%;
        display: flex;

        .form-body {
          flex-grow: 1;
          border-bottom: 1px solid var(--border-color);
          margin-left: 1.25rem;
          width: calc(100% - 4rem);

          .form-title {
            margin-bottom: 1rem;
            margin-top: 0.75rem;
            width: 100%;
            display: flex;
            align-items: center;

            cursor: pointer;

            .arrow {
              width: 5px;
              height: 5px;
              margin-left: auto;
              margin-right: 1rem;
              cursor: pointer;
            }
          }

          .form-content {
            display: none;

            .missing-doc_msg {
              display: flex;
              gap: 0 12px;

              .warning-circle {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background: var(--red);
                margin-bottom: 12px;

                p {
                  font-size: 10px;
                  color: white;
                  font-weight: lighter;
                }
              }

              .warning-msg {
                color: var(--red);
                font-size: 12px;
              }
            }

            .upload-error {
              margin-bottom: 1rem;
            }

            [role="presentation"] {
              padding: 100px;
              background-color: var(--global-green);
              border: 1px dashed var(--green);
              border-radius: 14px;
              text-align: center;
            }

            .files-form {
              display: flex;
              flex-wrap: wrap;
              gap: 1.5rem 8rem;

              .file {
                label {
                  margin-top: 0.5rem;
                }
              }
            }

            .files_summary {
              display: flex;
              flex-wrap: wrap;
              gap: 1rem;
              margin-top: 2rem;
              margin-bottom: 2rem;
              justify-content: space-between;
              flex: 1;

              .checkbox {
                display: inline-block;
                width: 20px;
                height: 20px;
                border: 1px solid black;
                border-radius: 5px;
                cursor: pointer;
                margin-right: 0.5rem;
                padding: 2px 4px;
              }

              input {
                position: absolute;
                opacity: 0;
              }

              label {
                position: relative;
                display: flex;
                align-items: center;
              }

              input:checked+.checkbox {
                background: var(--green);
                border: none;
              }

              input+.checkbox span {
                opacity: 0;
              }

              input+.checkbox span svg {
                width: 1rem;
              }

              input:checked+.checkbox span {
                opacity: 1;
              }

              .document {
                min-width: 48%;
                max-width: 280px;
                display: flex;
                flex-direction: column;

                select {
                  margin: 0;
                }

                .doc-upload_title {
                  margin-bottom: 6px;
                  font-size: 12px;
                }
              }
            }

            .imgs-preview {
              display: flex;
              gap: 12px;
              margin-top: 24px;

              .img-wrapper {
                width: 100px;
                height: 100px;
              }
            }

            .custom-file {
              border: 1px solid var(--green);
              cursor: pointer;
              background: var(--green);
              width: 7.5rem;
              height: 2.3rem;
              border-radius: 5px;
              color: white;
              display: flex;
              text-align: center;
              align-items: center;
              justify-content: center;

              p {
                font-size: 1rem;
                font-family: Arial, Helvetica, sans-serif;
              }
            }
          }
        }

        .circle-completed {
          width: 3rem;
          height: 3rem;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.16rem;
        }
      }

      margin-bottom: 4rem;
    }

    .update-order {
      .empty {
        text-align: center;
        margin: 2rem 0;
      }
    }

    .update-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid var(--border-color);
      padding-bottom: 1rem;

      .title {
        font-size: 1.2rem;
      }

      button {
        background-color: var(--green);
        color: white;
        padding: 5px 20px;
        border: none;
        border-radius: 5px;
      }

      .ignore-shipment-toggle {
        display: flex;
        align-items: center;
        margin-top: 1rem;
        cursor: pointer;
      
        label {
          display: flex;
          align-items: center;
          cursor: pointer;
      
          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
          }
      
          .checkbox {
            width: 20px;
            height: 20px;
            display: inline-block;
            border: 1px solid black;
            border-radius: 5px;
            margin-right: 10px;
            position: relative;
            cursor: pointer;
      
            span {
              opacity: 0;
            }
      
            svg {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 1rem;
            }
          }
      
          input:checked + .checkbox {
            background: var(--green);
            border: none;
      
            span {
              opacity: 1;
            }
          }
      
          .checkbox-label {
            font-size: 1rem;
            color: #303030;
          }
        }
      }
      
    }

    .delivery-order-container {
      margin-top: 12px;

      button {
        cursor: pointer;
      }

      label p {
        margin-bottom: 0.3rem;
      }

      .tab-header {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
      }

      .title {
        font-weight: bolder;
      }

      .delivery-order_form {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        margin-top: 12px;

        .input-box {
          width: 49%;

          select {
            margin-top: 6px;
            margin-left: 0;
            width: 100%;
          }

          select:focus {
            outline: none;
          }
        }

        .input-box.error {
          border: none !important;

          .css-13cymwt-control {
            border-color: red;
          }
        }

        .reference_number {
          display: flex;
          flex-direction: column;
        }

        input[type="text"].text-input {
          width: 100%;
          margin-top: auto;
          height: 35px;
          border: 1px solid var(--border-color);
          padding: 0 6px;
          border-radius: 5px;
        }

        .delivery-order_note {
          display: flex;
          flex-direction: column;
          gap: 6px;
          width: 100%;

          textarea {
            border: 1px solid var(--border-color);
            border-radius: 5px;
            min-height: 50px;
            padding: 6px;
          }
        }

        .buttons {
          display: flex;
          gap: 12px;
          width: fit-content;
          margin-left: auto;

          button {
            padding: 6px 24px;
            border: none;
            color: white;
            border-radius: 5px;
          }

          .delete {
            background-color: var(--red);
          }

          .submit {
            background-color: var(--green);
          }
        }
      }
    }
  }

  .notes {
    margin-top: 2rem;

    .title {
      font-size: 1.16rem;
    }

    .light-subtitle {
      font-size: 1rem;
      color: #303030;
      opacity: 0.5;
      margin-top: 0.5rem;
      margin-bottom: 1rem;
    }

    .by-admin {
      width: 100%;
      margin-top: 1rem;
      background-color: var(--global-green);
      padding: 13px 20px;
      border: 1px solid var(--border-color);
      border-radius: 5px;
      margin-bottom: 12px;
      display: flex;
      justify-content: space-between;

      p {
        color: var(--text-color);
        word-break: break-word;
      }

      .date {
        font-size: 10px;
        color: var(--text-color);
        opacity: 0.5;

        position: relative;
        top: 14px;
        left: 13px;
      }
    }

    .note-by-user {
      display: block;

      .note-body {
        background: white;
        border: 1px solid var(--border-color);
        border-radius: 5px;
        display: block;
        width: 100%;
        padding: 13px 20px;
        color: black;
        margin-bottom: 14px;
      }

      textarea:focus {
        outline: none;
      }

      .success-msg {
        color: var(--green);
        font-size: 1rem;
      }

      button {
        display: block;
        width: fit-content;
        margin-left: auto;
        background-color: var(--green);
        font-size: 12px;
        padding: 7px 25px;
        border: none;
        color: white;
        border-radius: 5px;
      }
    }

    .note-by-admin {
      background: var(--global-green);
      border: 1px solid var(--border-color);
      border-radius: 5px;
      display: block;
      width: 100%;
      padding: 13px 20px;
      color: black;
      margin-top: 14px;
    }
  }
}

.supplementary-file-section {
  .supplementary-files-container {
    flex-wrap: wrap;
    margin-top: 12px;

    .file-card {
      position: relative;
      overflow: hidden;

      .supplementary-x-container {
        width: 20px;
        height: 20px;
        border-radius: 50px;
        display: flex;
        position: absolute;
        top: -7px;
        background: #707070;
        align-items: center;
        justify-content: center;
        right: -5px;

        .supplementary-x {
          margin: 0 !important;
        }

        .file-card-name {
          overflow: hidden;
        }
      }
    }
  }

  .supplementary-files-title {
    margin-top: 12px;
  }
}

.right-side {
  width: 40%;
  position: relative; /* Keep the position relative for absolute positioning inside */
  .shipment-details {
    padding: 2rem 1.16rem 1.5rem 2rem;
    background: var(--global-green);
    border: 1px solid var(--border-color);
    border-radius: 5px;

    .title {
      font-size: 1.16rem;
      color: #303030;
      margin-bottom: 2.75rem;
    }

    .info-box {
      display: flex;
      border-bottom: 1px solid var(--border-color);
      padding: 0.65rem 0;

      .info-box-type {
        font-weight: bold;
        font-size: 1.16rem;
        width: 193px;
      }

      .info-box-value {
        color: #303030;
        font-size: 1.16rem;
        align-items: left;
        max-width: 50%;
        text-transform: uppercase;
      }

      .ref_no {
        button {
          background: transparent;
          border: none;
        }

        svg {
          color: gray;
          position: relative;
        }

        svg.green {
          color: var(--green);
        }
      }

      .ref_no input {
        background-color: transparent;
        border: none;
        font-size: 1.16rem;
        opacity: 1;
        color: black !important;
      }

      .ref_no input:focus {
        outline: none;
      }
    }

    #info-box-12 {
      border-bottom: none;
    }
  }
}


.files {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  gap: 0.616rem;

  .no-link {
    pointer-events: none;

    svg,
    p {
      color: var(--border-color);
    }
  }

  .active-link {

    svg,
    p {
      color: var(--green) !important;
      opacity: 1 !important;
    }
  }

  .file {
    width: 9.75rem;
    height: 11rem;
    text-align: center;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    padding: 1.6rem 1.83rem;

    p {
      color: #303030;
      opacity: 0.75;
    }

    svg {
      font-size: 3.3rem;
      color: #707070;
      opacity: 0.75;
      margin-bottom: 1.3rem;
    }
  }
}

@media screen and (max-width: 1200px) {
  #shipment-info {

    .left-side,
    .right-side {
      width: 100%;
    }

    .right-side {
      margin-top: 12px;

      .files {
        gap: 0;
        justify-content: space-around;
      }
    }

    flex-direction: column;
  }
}

.page-content.loading {
  background: linear-gradient(-45deg, #efefef, #ffffff, #efefef, #ffffff);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  border-radius: 5px;
  min-height: calc(100vh - 200px);
  margin-top: 1rem;
  margin-left: 1rem;
  width: 100%;
}

.review-7501-toggle {
  display: flex;
  align-items: center;
  gap: 0px 8px;
  margin-top: 2rem;
  cursor: pointer;

  .checkbox-ship {
    width: 20px;
    height: 20px;
    border: 1px solid #707070;
    border-radius: 5px;

    .checked-item {
      color: var(--green);
      font-weight: 600;
      position: relative;
      top: 0;
      left: 3px;
      font-size: 14px;
    }
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}