.notif-container{
    background: #EB1B2321;
    padding: 1.2rem 2rem 1.2rem 1.2rem;
    border-radius: 5px;

    max-height: 3.5rem;

    margin-bottom: 2.5rem;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.close-btn{
    font-size: 2rem;
    font-weight: bold;
}