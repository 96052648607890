.boxes {
  display: flex;
  gap: 0rem 3rem;

  @media screen and (max-width: 370px) {
    flex-direction: column !important;
  }
}

@media screen and (max-width:1000px) {
  .boxes {
    width: 100%;
    gap: 0px 12px;

    .box{
      max-width: none!important;
      min-width: none!important;
      width: auto !important;
      padding: 9px 10px;
      flex-grow: 1;
      text-align: center;
      .box-text{
        margin-left: 0;
      }
    }
  }
}