#grey-line {
    border-top: 1px solid #e9e9e9;
    width: calc(100vw - 8.4rem);
}

#responsive-logo {
    display: none;
    width: 4rem;
}

.cont {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 4.6rem 0rem 2.6rem 2.4rem;

    .breadcrumbs {
        width: 100%;

        svg {
            margin: 0 0.25rem 0 0.5rem;
        }
    }

    h3 {
        color: #303030;
        font-size: 1.3rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
    }
}

@media screen and (max-width: 1200px) {
    #responsive-logo {
        display: block !important;
    }

    #grey-line {
        width: 100vh;
        margin-bottom: 2rem;
    }
}

.page-title.loading {
    h3 {
        background: linear-gradient(-45deg, #efefef, #ffffff, #efefef, #ffffff);
        background-size: 400% 400%;
        animation: gradient 15s ease infinite;
        border-radius: 5px;
        min-width: 20rem;
        color: transparent;
    }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}