#main-container {
  table {
    width: 100%;
    text-align: left;
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    border-collapse: collapse;
    
    tr {
      border-top: 1px solid var(--border-color);
    }

    td,
    th {
      padding: 1.3rem 0.9rem;
    }

    td {
      border-top: 1px solid var(--border-color);
    }

    tbody {
      tr {
        #file_number {
          font-weight: bold;
        }

        &:hover {
          background-color: #23b57420;
        }
      }
    }

    svg {
      width: 20px;
    }

    #containersLessThan1280px {
      display: none;
    }

    @media screen and (max-width: 1280px) {
      #containersLessThan1280px {
        display: flex !important;
      }

      #containersOver1280px {
        display: none !important;
      }
    }

    .container-extensor {
      color: var(--green);
      font-size: 10px;
      text-decoration: underline;
      margin-left: 5px;
    }
  }
}

.loading-table {
  background: linear-gradient(-45deg, #efefef, #ffffff, #efefef, #ffffff);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  border-radius: 5px;
  width: 100%;
  min-height: 500px;
}

.pass-fda {
  color: green;
}

.fail-fda {
  color: red;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}